export default {
    props: {
        attachingRootId: {
            type: [String, Number],
            default: ''
        },
        attachingSourceId: {
            type: [String, Number],
            default: ''
        },
    }
}