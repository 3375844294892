<template>
    <div>
        <div @click="visible = true" >
            <slot name="openButton">
            </slot>
        </div>
        <a-drawer
            v-if="isMobile"
            title="Загрузить файл"
            placement="bottom"
            class="files_attaching_drawer"
            destroyOnClose
            :zIndex="zIndex || 1000"
            width="100%"
            :visible="visible"
            @close="closeModal">
            <div class="drawer_body">
                <Files
                    ref="fileAttachFiles"
                    :attachingRootId="attachingRootId"
                    :attachingSourceId="attachingSourceId"
                    :attachmentFiles="attachmentFiles"
                    attaching
                    :createFounder="createFounder"
                    :oneUpload="oneUpload"
                    :changeSelect="changeSelect"
                    :mobileApp="mobileApp"
                    @closeParentModal="closeModal"
                    :isMyFiles="true"
                    :isFounder="true"
                    :isStudent="true" />
            </div>
            <div class="drawer_footer">
                <a-button size="large" :disabled="buttonDisabled" type="primary" class="mb-1" block @click="attachSelected()">
                    Прикрепить
                </a-button>
                <a-button type="ui" ghost block @click="closeModal()">
                    Закрыть
                </a-button>
            </div>
        </a-drawer>
        <a-modal 
            v-else
            :dialogStyle="{ top: '30px' }"
            class="files_attaching_drawer"
            v-model="visible" 
            title="Загрузить файл"
            :zIndex="zIndex || 1000"
            :width="700"
            @ok="attachSelected"
            :getContainer="getModalContainer"
            okText="Прикрепить"
            @close="closeModal"
            cancelText="Отмена">
            <Files
                ref="fileAttachFiles"
                :attachingRootId="attachingRootId"
                :attachingSourceId="attachingSourceId"
                :attachmentFiles="attachmentFiles"
                :showDeviceUpload="showDeviceUpload"
                attaching
                :createFounder="createFounder"
                :oneUpload="oneUpload"
                :changeSelect="changeSelect"
                :mobileApp="mobileApp"
                @closeParentModal="closeModal"
                :zIndex="zIndex"
                :isMyFiles="true"
                :isFounder="true"
                :isStudent="true" />
            <template slot="footer">
                <div class="flex items-center justify-end">
                    <a-button 
                        type="ui"
                        ghost 
                        @click="closeModal()">
                        Закрыть
                    </a-button>
                    <a-button 
                        :disabled="buttonDisabled" 
                        type="primary" 
                        class="ml-2" 
                        @click="attachSelected()">
                        Прикрепить
                    </a-button>
                </div>
            </template>
        </a-modal>

        <div v-if="showAttached" class="attached_files">
            <div 
                v-for="(file, index) in attachmentFiles" 
                :key="index"
                class="attached_file">
                <a-tooltip :title="`${file.name}.${file.extension}`">
                    <div class="file_image_wrapper">
                        <img 
                            :data-src="file.is_image ? file.path : fileIcon(file)" 
                            alt=""
                            class="file_icon lazyload"
                            :class="file.is_image && 'file_image'">
                    </div>
                    <div class="file_name font-light text-center truncate">
                        {{ file.name }}
                    </div>
                </a-tooltip>
                <span 
                    class="detach_file" 
                    @click="deleteFile(file)">
                    <i class="fi fi-rr-trash"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { filesFormat } from '@/utils'
import { mapActions } from 'vuex'
import attachingSourcesProps from '../mixins/attachingSourcesProps'

export default {
    mixins: [attachingSourcesProps],
    props: {
        action: {
            type: String,
            // default: "/api/v1/common/upload/"
            default: `${process.env.VUE_APP_API_URL}/common/upload/`        
        },
        zIndex: {
            type: Number,
            default: null
        },
        attachmentFiles: {
            type: Array,
            default: ()=> []
        },
        attachmentFileIds: {
            type: Array,
            default: ()=> []
        },
        maxFileCount: {
            type: Number,
            default: 5
        },
        maxMBSize: {
            type: Number,
            default: 1
        },
        showAttached: {
            type: Boolean,
            default: true
        },
        mobileApp: {
            type: Boolean,
            default: false
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        getModalContainer: {
            type: Function,
            default: () => document.body
        },
        clearAttachmentsAfterAttach: {
            type: Boolean,
            default: true
        },
        showDeviceUpload: {
            type: Boolean,
            default: true
        }
    },
    components: {
        Files: () => import('./Files')
    },
    data() {
        return {
            visible: false,
            buttonDisabled: true
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
    },
    methods: {
        ...mapActions('files', ['uploadFiles']),
        changeSelect(select) {
            select > 0 ? this.buttonDisabled = false : this.buttonDisabled = true
        },
        async handleChange(info) {
            const status = info.file.status;
            if (status === 'done') {
                const file = info.file.response[0]
                if(this.attachingRootId)
                    await this.confirmUpload(file)
                else
                    this.attachmentFiles.push(file)
                this.closeModal()
            } else if (status === 'error') {
                this.$message.error(`Не удалось загрузить файл "${info.file.name}"`);
            }
        },
        async confirmUpload(file) {
            try {
                const folderId = this.attachingSourceId !== this.attachingRootId ? this.attachingSourceId : null
                if(!this.isMyFiles) {
                    await this.uploadFiles({
                        files: [file.id], 
                        rootId: this.attachingRootId, 
                        folderId: folderId
                    })
                } else {
                    this.ADD_FILE({
                        data: file, 
                        key: 'my_files'
                    })
                }
                this.$message.success(`Файл "${file.name}" успешно загружен.`);
                
            } catch(e) {
                console.log(e)
                this.$message.error(this.$t('chat.error'))
            }
        },
        fileIcon(file) {
            const extension = filesFormat.find(format => format === file.extension)
            if(extension)
                return require(`@/assets/images/files/${extension}.svg`)
            else
                return require(`@/assets/images/files/file.svg`)
        },
        openFileModal() {
            this.visible = true
        },
        deleteFile(file) {
            const deletingFileIndex = this.attachmentFiles.findIndex(attachedFile => attachedFile.id === file.id)
            this.attachmentFiles.splice(deletingFileIndex, 1)
        },
        attachFiles(files) {
            if(files.length > this.maxFileCount)
                this.$message.warning(this.$t('chat.file_max_count', {count: this.maxFileCount}))
            else
                this.handleFileUpload(files)
        },
        handleFileUpload(files) {
            Array.prototype.forEach.call(files, async (file, i) => {
                let formData = new FormData()
                if(this.isMoreThanMaxSize(file.size)) {
                    this.$message.warning(this.$t('chat.file_size_error', {
                        name: file.name, 
                        filesize: this.maxMBSize
                    }))
                    return false
                }

                formData.append('upload', file)

                try {
                    // this.fileLoading = true
                    const {data} = await this.$http.post('/common/upload/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    if(data?.length)
                        this.attachmentFiles.push(data[0])
        
                } catch(e) {
                    this.$message.error(this.$t('chat.error'))
                } finally {
                    // this.fileLoading = false
                }
            })
        },
        isMoreThanMaxSize(size) {
            return size > (this.maxMBSize * (1024 ** 2))
        },
        closeModal() {
            this.visible = false
        },
        attachSelected() {
            this.$nextTick(() => {
                this.$refs.fileAttachFiles.attachSelected()
                if(this.clearAttachmentsAfterAttach) {
                    // this.$refs.fileAttachFiles.clearAttachments()
                }
            })
        }

    },
}
</script>

<style scoped lang="scss">
.files_attaching_drawer{
    &::v-deep{
        .ant-drawer-content-wrapper{
            height: 100%!important;
        }
        .ant-drawer-content,
        .ant-drawer-wrapper-body{
            overflow: hidden;
        }
        .ant-modal-footer{
            position: sticky;
            bottom: 0;
            z-index: 100;
            background: #ffffff;
            width: 100%;
        }
        .ant-drawer-body{
            height: calc(100% - 40px);
            padding: 0px;
            position: relative;
        }
        .drawer_body{
            padding: 15px;
            height: calc(100% - 90px);
            overflow-y: auto;
            position: relative;
        }
        .drawer_footer{
            height: 90px;
            padding: 5px 15px;
            border-top: 1px solid var(--borderColor);
        }
        .float_add{
            --safe-area-inset-bottom: env(safe-area-inset-bottom);
            bottom: calc(100px + var(--safe-area-inset-bottom));
        }
        .file_list{
            min-height: 100%;
            .vue-recycle-scroller{
                max-height: 100%;
            }
        }
        .attaching_scroller{
            max-height: 100%!important;
        }
    }
}
.attached_files {
    display: grid;
    gap: 8px;
    grid-auto-rows: 100px;
    grid-template-columns: repeat(auto-fit, 100px);
    .attached_file {
        position: relative;

        border: 1px solid #d9d9d9;
        border-radius: 4px;
        .file_image_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
    
            height: 70px;
            
            padding: 8px;
            .file_icon {
                width: 100%;
                max-height: 100%;
                
                object-fit: contain;
            }
            .file_image {
                border: 1px solid var(--borderColor);
                border-radius: 4px;
            }
        }
        .file_name {
            margin-top: 4px;
            padding: 0 8px;

            line-height: 1.1;
        }
        .detach_file {
            position: absolute;
            top: 8px;
            right: 6px;

            line-height: 1;
            font-size: 18px;

            cursor: pointer;
        }
    }
}
</style>

<style lang="scss">
.files_attaching_drawer {
    .filter_pop_wrapper {
        min-width: 0 !important;
    }
    @media(max-width: 1024px) {
        .list_scroller_wrap .scroller {
            max-height: calc(var(--vh, 1vh) * 100 - 450px);
        }
        .ant-modal {
            $top-indent: 30px;
            top: $top-indent;
        }
        .ant-modal-header {
            padding-left: 15px;
            padding-right: 15px;
        }
        .ant-modal-body {
            padding: 15px;
        }
    }
}
</style>